$checkbox-radius: 0.3rem;
$checkbox-border: 0.1rem solid $grey;
$checkbox-block-background: $red;
$checkbox-checked-width: 0.2rem;
$checkradio-focus: 0;
$checkradio-top-offset: 0rem;
$checkbox-padding: 0.4rem 0.5rem 0.4rem 0;
$checkbox-size: 1.8;
$checkradio-colors: (
  'red': (
    $link,
    $link-invert,
  ),
  'red-dark': (
    $red-dark,
    $red-invert,
  ),
);
