@import 'variables';

@font-face {
  font-family: '#{$icomoon-font-family}';
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?nqmdmq');
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?nqmdmq#iefix')
      format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?nqmdmq')
      format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?nqmdmq')
      format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?nqmdmq##{$icomoon-font-family}')
      format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.eb {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.eb-takeaway1 {
  &:before {
    content: $eb-takeaway1;
  }
}
.eb-restaurants {
  &:before {
    content: $eb-restaurants;
  }
}
.eb-bars {
  &:before {
    content: $eb-bars;
  }
}
.eb-icon-explore-black {
  &:before {
    content: $eb-icon-explore-black;
  }
}
.eb-takeaway {
  &:before {
    content: $eb-takeaway;
  }
}
.eb-f5 {
  &:before {
    content: $eb-f5;
  }
}
.eb-f4 {
  &:before {
    content: $eb-f4;
  }
}
.eb-f2 {
  &:before {
    content: $eb-f2;
  }
}
.eb-f3 {
  &:before {
    content: $eb-f3;
  }
}
.eb-f1 {
  &:before {
    content: $eb-f1;
  }
}
.eb-call {
  &:before {
    content: $eb-call;
  }
}
.eb-chat {
  &:before {
    content: $eb-chat;
  }
}
.eb-check {
  &:before {
    content: $eb-check;
  }
}
.eb-check-mark {
  &:before {
    content: $eb-check-mark;
  }
}
.eb-close {
  &:before {
    content: $eb-close;
  }
}
.eb-rr {
  &:before {
    content: $eb-rr;
  }
}
.eb-rl {
  &:before {
    content: $eb-rl;
  }
}
.eb-ru {
  &:before {
    content: $eb-ru;
  }
}
.eb-rd {
  &:before {
    content: $eb-rd;
  }
}
.eb-filter {
  &:before {
    content: $eb-filter;
  }
}
.eb-insta {
  &:before {
    content: $eb-insta;
  }
}
.eb-mail1 {
  &:before {
    content: $eb-mail1;
  }
}
.eb-mail2 {
  &:before {
    content: $eb-mail2;
  }
}
.eb-apple {
  &:before {
    content: $eb-apple;
  }
}
.eb-home {
  &:before {
    content: $eb-home;
  }
}
.eb-info {
  &:before {
    content: $eb-info;
  }
}
.eb-gift {
  &:before {
    content: $eb-gift;
  }
}
.eb-fork {
  &:before {
    content: $eb-fork;
  }
}
.eb-lock {
  &:before {
    content: $eb-lock;
  }
}
.eb-file {
  &:before {
    content: $eb-file;
  }
}
.eb-faq {
  &:before {
    content: $eb-faq;
  }
}
.eb-contacts {
  &:before {
    content: $eb-contacts;
  }
}
.eb-news {
  &:before {
    content: $eb-news;
  }
}
.eb-map1 {
  &:before {
    content: $eb-map1;
  }
}
.eb-list {
  &:before {
    content: $eb-list;
  }
}
.eb-glass {
  &:before {
    content: $eb-glass;
  }
}
.eb-fb {
  &:before {
    content: $eb-fb;
  }
}
.eb-map2 {
  &:before {
    content: $eb-map2;
  }
}
.eb-burger {
  &:before {
    content: $eb-burger;
  }
}
.eb-play {
  &:before {
    content: $eb-play;
  }
}
.eb-gplay {
  &:before {
    content: $eb-gplay;
  }
}
.eb-pb {
  &:before {
    content: $eb-pb;
  }
}
.eb-pl {
  &:before {
    content: $eb-pl;
  }
}
.eb-pt {
  &:before {
    content: $eb-pt;
  }
}
.eb-pr {
  &:before {
    content: $eb-pr;
  }
}
.eb-checkbox-checked {
  &:before {
    content: $eb-checkbox-checked;
  }
}
.eb-checkbox-unchecked {
  &:before {
    content: $eb-checkbox-unchecked;
  }
}
