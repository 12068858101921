$container-offset-small: (2 * $gap);

.container-small {
  flex-grow: 1;
  margin: 0 auto;
  position: relative;
  width: auto;

  @include desktop() {
    max-width: $desktop - $container-offset-small;
    &.is-fluid {
      margin-left: $gap;
      margin-right: $gap;
      max-width: none;
    }
  }

  @include until-widescreen() {
    &.is-widescreen {
      max-width: $widescreen - $container-offset-small;
    }
  }
  @include until-fullhd() {
    &.is-fullhd {
      max-width: $fullhd - $container-offset-small;
    }
  }
  @include widescreen() {
    max-width: $widescreen - $container-offset-small;
  }

  // @include fullhd() {
  //   max-width: $fullhd - $container-offset-small;
  // }
}
