@use "sass:math";

.card {
  position: relative;
  overflow: hidden;

  &.is-rounded-only {
    border-radius: $radius-large;
    overflow: hidden;
  }

  &.is-rounded {
    border-radius: $card-radius;
    padding: 1.25rem;

    .card-image.is-rounded {
      border-radius: $card-radius;
      overflow: hidden;
    }
  }

  .card-footer {
    color: $primary-invert;
  }

  .summary {
    font-weight: $weight-black;
    font-size: $size-medium;
    height: $size-medium * 4;
    overflow: hidden;

    @include tablet() {
      height: ($size-medium * 3) + 0.5rem;
    }
  }

  &.card-list {
    flex-direction: column;
    display: flex;
    height: 100%;
    position: relative;
    z-index: 2;
    overflow: hidden;
    border-radius: $radius-large;
    text-align: center;
    margin-bottom: 1.2rem;

    .title {
      font-size: $size-normal;
      text-transform: uppercase;
      font-weight: $weight-bold;
    }

    .summary {
      font-size: $size-small;
      color: $grey-dark;
      font-weight: $weight-bold;
      line-height: 1.3;

      @include tablet() {
        font-size: 1rem;
      }
    }

    .card-content {
      padding: $size-small $size-small 0; // remove padding bottom because of auto hight
      @include desktop() {
        padding: 1.5rem 1.5rem 0;
      }

      .title {
        font-size: $size-small;
        margin-bottom: math.div($size-small, 2.5);
        line-height: 1.3;
        @include tablet() {
          font-size: 1rem;
          margin-bottom: 1rem;
        }
      }
    }

    .card-image {
      padding-left: $size-small;
      padding-right: $size-small;
      padding-top: $size-small;

      @include desktop() {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-top: 1.5rem;
      }

      .image {
        border-radius: $radius-large;
        overflow: hidden;
      }
    }
  }
}
