@use "sass:math";

$productContentPadding: 1.5rem;

.filter-buttons {
  margin-top: 1rem;
  margin-bottom: 4rem;
  @include tablet() {
    margin-bottom: 0;
  }

  .button {
    letter-spacing: 2px;
    color: $black;
    height: 3.5rem;
    width: 100%;
    font-size: $size-normal;
    font-weight: $weight-black;

    &:focus {
      box-shadow: none;
    }

    &.is-red-dark {
      color: $white;
    }
  }
}

.button.filter-buttons--float {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 95%;
  height: 60px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 5px;
  box-shadow: $box-shadow;
  font-size: 12px;
  z-index: 99;
}

#product-filter-form {
  border: 0;
  margin-bottom: 100px;
  @include tablet() {
    margin-bottom: 0;
  }

  .is-switcher .button {
    @include mobile() {
      padding-top: 1.15rem;
      padding-bottom: 1.15rem;
      height: 3.5rem;
    }
  }

  .field {
    padding: 0.18rem 0;
    @include tablet() {
      padding: 0;
    }
  }
}

.pane-outer {
  padding: 0;
  font-size: 14px;
  //@include tablet() {
  //  padding: 0;
  //}

  //@include desktop() {
  //padding: 0 3rem;
  //}
}

.pane {
  border-radius: 0;
  background-color: $white;
  position: relative;
  padding: 1rem;

  @include tablet() {
    //background-color: rgba($white, 0.04);
    border-radius: $radius-large;
  }

  @include desktop() {
    padding: 1.5rem;

    //padding: 1rem 3rem;
  }

  .columns {
    margin-bottom: 0;

    .column {
      padding-bottom: 0;
    }
  }

  &.is-white {
    background-color: $white;
  }

  .label-cb,
  .label {
    font-weight: $weight-black;
    font-size: $size-8;
  }

  .is-checked .label-cb {
    color: $red-dark;
  }

  .button-slider-text {
    font-size: $size-xsmall;

    //@include desktop() {
    //  font-size: $size-small;
    //}
  }

  .buttons {
    &.is-switcher {
      margin: 0;

      &:not(:last-child) {
        @include mobile() {
          margin-bottom: 1.5rem;
        }
      }

      .button.has-icon .button-slider-icon {
        font-size: $size-normal;
        //@include desktop() {
        //  font-size: $size-medium;
        //}
      }
    }
  }
}

.section-products {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: $size-xlarge !important;
  z-index: 2;

  .bxx {
    color: $white;
    font-size: $size-large;
    text-align: center;
    font-weight: $weight-bold;

    .button {
      border-radius: $radius-large;
    }

    .buttons {
      margin-top: 1rem;
      margin-bottom: 1.5rem;
    }
  }

  &.hide {
    display: none;
  }

  &.filters {
    border: 0;
    @include mobile() {
      padding-top: 0;
      padding-bottom: 0 !important;
    }
  }

  .buttons.is-switcher {
    border-radius: $radius-large;

    .button {
      .button-slider {
        border-radius: $radius-large;
      }
    }
  }
}

.grid-list {
  display: flex;
  flex-flow: wrap;
  height: 100%;
  width: 100%;

  &.between {
    justify-content: space-between;
  }

  &.center {
    align-items: center;
  }

  ._item,
  .item {
    flex-basis: auto;
    flex-grow: 0;
    width: 100%;

    @include mobile() {
      width: (100% * 0.5);
    }
    @include tablet() {
      width: (100% * 0.25);
    }
    @include desktop() {
      width: (100% * 0.2);
    }
    @include widescreen() {
      width: math.div(100%, 6);
    }

    &._12 {
      width: 100%;
    }

    &._7 {
      width: (100% * 0.7);
    }

    &._3 {
      width: (100% * 0.3);
    }

    &._3 {
      width: (100% * 0.2);
    }

    &._4 {
      width: (100% * 0.4);
    }

    &._6 {
      width: (100% * 0.6);
    }

    &._5 {
      width: (100% * 0.5);
    }
  }

  &._50 {
    width: 100%;
    align-items: center;

    .item {
      width: 100%;
      @include tablet() {
        width: math.div(100%, 2.02);
      }

      &:first-child {
        //padding-right: 1rem;
      }
    }
  }

  &.mobile-full {
    @include mobile() {
      .item {
        width: 100%;
      }
    }
  }
}

.image-preview {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  min-height: 144px;

  .image {
    position: relative;
    @include tablet() {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    img {
      position: relative;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: none;
      @include tablet() {
        position: absolute;
        top: 50%;
        left: 50%;
        width: auto;
        height: auto;
        max-height: none;
        max-width: none;
        min-height: 100%;
        min-width: 100%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

.product-info {
  line-height: 1.3;
  font-size: $size-small;
  text-align: center;
  font-weight: $weight-medium;
  //text-transform: uppercase;

  .item-tags {
    .button {
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      width: 100%;
    }
  }

  .items {
    position: relative;

    border-bottom: 1px solid $grey-3;
    padding: 0.5rem 0.8rem;
    @include desktop() {
      padding: 1rem 1.8rem;
    }

    &.idesc {
      //padding-bottom: 0;
      min-height: 185px;
    }

    &.imenus {
      height: 5.275rem;
      line-height: 1.1;
    }

    &.ibook {
      .button {
        //font-size: $size-small;
        border-radius: $radius-large;
        padding-top: 0.8rem;
        padding-bottom: 0.8rem;
        //height: 3.75rem;
      }
    }
  }

  .label {
    text-transform: uppercase;
    margin-bottom: 0;
  }

  .name {
    font-size: 1.3125rem;
    color: $red;
    font-weight: $weight-bold;
    text-transform: uppercase;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    position: relative;
    display: inline;
  }

  .desc {
    height: 2.3rem;
    overflow: hidden;
    font-weight: 400;
  }

  .menus {
    border-radius: $radius-large;
    box-shadow: $box-shadow;
    text-align: left;
    cursor: pointer;
    margin-left: 0;
    margin-right: 0;
    overflow: hidden;

    > a {
      white-space: nowrap;
      display: block;
      padding: 0.7rem 0.5rem;

      @include tablet() {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
      }

      @include desktop() {
        padding: 0.7rem 0.5rem;
      }

      @include widescreen() {
        padding: 0.7rem 1rem;
      }

      &:not(:first-child) {
        margin-top: -(0.7rem * 0.5);
        @include tablet() {
          margin-top: 0.25rem;
        }

        @include desktop() {
          margin-top: -(0.7rem * 0.5);
        }
      }
    }

    .columns {
      padding: 0;
      justify-content: center;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }

    a {
      color: $black;
      text-transform: uppercase;
      outline: none !important;
    }

    .tag {
      height: 1.8rem;
      padding: 0.35em 0.75em;
      font-size: $size-6;
      margin-left: 0.5rem;
      min-width: 5rem;
    }
  }

  .price {
    text-transform: uppercase;
    white-space: nowrap;
    text-align: right;
    font-size: $size-6;
    letter-spacing: 0.5px;
    font-weight: $weight-bold;

    small {
      font-size: $size-9;
      margin-left: 0.19rem;
    }

    .is-normal {
      color: $grey-dark;
      text-decoration: none;
      position: relative;
      display: inline-block;

      // line through
      &:after {
        content: ' ';
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        height: 1px;
        background-color: $grey-dark;
      }
    }
  }

  .button {
    font-size: $size-normal;
    font-weight: $weight-black;
    @include tablet() {
      padding-left: 0.4rem;
      padding-right: 0.4rem;
      //font-size: $size-small;
    }

    @include desktop() {
    }
  }
}

// filters
.filters {
  background-color: $white;
  color: invert($white);
  border-radius: $radius-large;
  border: 0;
  position: relative;
  padding: 0;

  @include tablet() {
    //padding: 1rem;
  }

  @include desktop() {
    //padding: $size-xlarge;
  }

  .label {
    margin-bottom: 0;
    text-align: center;
    white-space: nowrap;

    &.is-left {
      text-align: left;
    }
  }

  .time-label-wrap {
    //position: absolute;
    //margin-left: -1.3rem;
    margin-top: -1.6rem;
    display: flex;
    flex-flow: nowrap;
    flex-direction: row;
    justify-content: space-between;

    .time-label--first {
      justify-content: flex-start;
      margin-right: 5px;
    }

    .time-label--second {
      justify-content: flex-end;
    }

    @include tablet() {
      //margin-left: -1.1rem;
      //margin-top: -1.875rem;
    }
  }

  .time-label {
    color: invert($white);
    font-weight: $weight-black;
    font-size: $size-small;
    padding-bottom: 5px;

    @include tablet() {
      font-size: $size-9;
    }

    &--first {
      margin-left: -5px;
    }

    &--second {
      margin-right: -5px;
    }

    &.time-start {
      display: block;
      text-align: left;
    }

    &.time-end {
      display: block;
      text-align: right;
    }
  }

  .field {
    position: relative;
    border: 2px solid $grey;
    padding: 0;
    border-radius: $radius-large;

    flex-direction: column;
    display: flex;
    height: 100%;
    //z-index: 2;

    @include tablet() {
      height: 57px;
    }

    &#field-filter-time {
      padding: 0.5rem 1rem;
      @include tablet() {
        padding: 0 1rem;
      }
    }

    .dropdown {
      .dropdown-menu {
        margin-right: -2px;
        margin-left: -2px;
        margin-top: -6px;
      }
    }

    .dropdown-trigger .button {
      width: 100%;
      justify-content: left;
      padding: 0.5rem 1rem !important;
      @include tablet() {
        padding: 0 1rem !important;
      }
    }

    .input,
    .icon,
    .control {
      height: 54px;
    }

    .label {
      color: $black;
    }

    .input {
      border: 0;
      font-weight: bold;
    }

    &.is-active {
      .dropdown {
        .dropdown-menu {
          display: block;
          opacity: 1;
          z-index: 5;
          left: 0;
          right: 0;
          min-width: 100%;
        }

        .dropdown-item {
          line-height: 1.8;
        }
      }
    }

    .dropdown {
      display: block;
      width: 100%;
      line-height: normal;
      //margin-top: 1rem;
      min-height: 2.4rem;

      .dropdown-box {
        margin-top: 1rem;
      }

      .dropdown-trigger {
        .button {
          border: 0;
        }
      }

      .button {
        color: $black;
        padding: 0;
        font-weight: $weight-black;
        font-size: $size-normal;
        height: 3.125rem;

        @include desktop() {
          //font-size: $size-normal;
        }

        &:focus {
          box-shadow: none;
        }
      }

      .is-control-mobile.dropdown-menu {
        display: none;
        border: 0;
        opacity: 0;
        right: 0;
        min-width: 100%;
        transition: all 0.2s ease-in-out;

        @include tablet() {
          margin-left: -14px;
          margin-right: -14px;
        }

        @include desktop() {
          margin-left: -16px;
          margin-right: -16px;
        }
      }

      .dropdown-content {
        box-shadow: none;
        padding-top: 0;
        padding-bottom: 0;
        border-top: 0;
        border-radius: 0 0 $radius-large $radius-large;
        overflow: hidden;
      }

      .dropdown-item {
        color: $black;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        transition: all 0.18s ease-in-out;
        line-height: 0;
        background-color: $white;

        &:hover,
        &.is-active {
          background-color: #f5f5f5;
        }
      }
    }

    .filter-icon {
      z-index: 1;
      font-size: 10px;
      color: $red-dark;
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -9.1px;
      margin-right: 1rem;
      @include desktop() {
        font-size: 12px;
      }
    }
  }
}

.info-side {
  //border-left: 2px solid $grey;
  height: 100%;
  margin-left: 0;

  .item-menus {
    display: none;
    background-color: $blue;
    border-radius: $radius-large;

    @include tablet() {
      display: block;
    }

    .button {
      background-color: $white;
      border-color: $primary;
      color: $primary;
      border-width: 0.5px;
      border-radius: $radius-large !important;
    }
  }

  .item-address {
    display: block;
    margin-top: 2rem;

    @include tablet() {
      display: none;
      margin-top: 0;
    }

    .title {
      text-align: left;
      font-size: $size-7;
      color: $grey-dark;
      margin-bottom: 1rem !important;
      @include tablet() {
        font-size: $size-5;
        text-align: center;
        color: $black;
      }
    }

    .frame {
      box-shadow: $box-shadow;
    }

    .label {
      display: none;
      @include tablet() {
        display: block;
      }
    }
  }
}

.info-side {
  padding-left: 0;
  @include tablet() {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }

  @include desktop() {
    padding-left: 2.2rem;
    padding-right: 0.2rem;
  }
}

.info-nav {
  padding: 1.5rem;

  .button.booking-button,
  .button {
    height: 60px;
    border-radius: $radius-large;
    position: relative;
    display: block;
    font-size: $size-normal;
    padding-top: 1rem;
    padding-bottom: 1rem;
    line-height: 1.6;
    text-transform: uppercase;

    @include desktop() {
      padding-top: 1.3rem;
      padding-bottom: 1.3rem;
    }

    &:not(:last-child) {
      margin-bottom: 0.7rem;
      @include desktop() {
        //margin-bottom: 1rem;
      }
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }

    &.disabled {
      background-color: $grey-dark !important;

      &:hover,
      &:active {
        background-color: $grey-dark !important;
      }
    }

    .icon {
      margin-top: 6px;
      position: absolute;
      right: 1.9rem;
      top: 8px;
    }

    &.is-active,
    &:hover {
      background-color: $red;
      color: $primary-invert !important;

      border-color: $primary;

      &:before {
        content: ' ';
        background-repeat: no-repeat;
        background-position: left;
        position: absolute;
        left: 0;
        width: 11px;
        height: 22px;
        margin-left: -0.625rem;
      }
    }
  }
}

.info-view {
  position: relative;
  white-space: pre-wrap;
  z-index: 3;
  font-weight: $weight-normal;
  //padding-top: 0;
  //@include tablet() {
  //padding-top: 40px;
  //}

  .video {
    border-radius: $radius-large;
  }

  .content-header {
    font-weight: $weight-medium;
    font-size: 0.9rem;

    p,
    .box--time,
    .label.label--address {
      font-size: 0.9rem;
    }

    .box--time {
      margin-bottom: 0;
    }

    .title {
      font-size: 1.2rem;
      text-transform: none;
      @include tablet() {
        font-size: 1.4rem;
        text-transform: uppercase;
      }
    }
  }

  .title:not(.is-spaced) + .subtitle {
    //margin-top: -1.35rem;
  }
}

.content-header__desc {
  font-weight: $weight-normal;
  line-height: 1.2;
  margin-bottom: 0 !important;
}

.info-view-galleri {
  position: relative;
  z-index: 2;
}

.tag-price {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-end;

  .sale,
  .normal {
    font-weight: $weight-black;
    text-transform: uppercase;
    font-size: $size-normal;
    line-height: 1;
    text-align: center;
    display: inline-block;
  }

  .normal {
    font-weight: $weight-bold;
    color: $grey-dark;
    text-decoration: line-through;
    margin-right: 0.4rem;
    margin-left: 0.4rem;
    font-size: $size-small;

    small {
      display: block;
      font-size: $size-9;
    }
  }

  .sale {
    background-color: $red-light;
    color: $red-dark;
    font-weight: $weight-black;

    &.tag {
      width: auto;
      height: auto;
      display: inline-block !important;
      padding: 0.45rem 1rem 0.6rem 1rem;
    }

    small {
      display: block;
      font-size: $size-8;
    }
  }
}

.product-content {
  padding: $productContentPadding;

  @include tablet() {
    padding: $productContentPadding 0;
  }

  .label {
    font-weight: $weight-normal;
  }

  &--display {
    padding-top: 0;
    //padding-bottom: 0;
  }

  &--descktop {
    display: none;
    @include tablet() {
      display: block;
    }
  }

  &.v-map {
    padding: 0;
    margin-bottom: 0;

    .grid-list {
      display: block;
      @include tablet() {
        display: none;
      }
    }

    @include tablet() {
      margin-bottom: 3rem;
    }

    .pop-page-outer {
      min-height: 500px;
      position: absolute;
      @include tablet() {
        position: relative;
      }
    }
  }

  &.v-list {
    padding-top: 0;
    @extend .grid-list;
    @include mobile() {
      display: block;
      padding: 0;
      .item {
        width: 100%;
        display: block;
        overflow: initial;
        white-space: normal;

        padding: 0 0.8rem;
        margin-bottom: 2rem;

        &.is-active,
        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}

#eb-app {
  z-index: 3;
  position: relative;
}

#eb-filter-root {
  z-index: 4;
  //position: relative;
}

#eb-boxb-root {
  z-index: 2;
  position: relative;
}

@mixin fixImg {
}

.fixed-image {
  //min-height: 11.75rem;
  height: 235px;
  position: relative;

  .image {
    position: relative;

    img {
      transform: none;
      left: 0;
      right: 0;
      width: 100%;
      position: relative;
    }
  }
}

.book-button {
  width: 100%;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  padding: 0 1rem;

  .button {
  }

  .is-huge {
    margin-bottom: 0.5rem;
    @include tablet() {
      margin-bottom: 1.1rem;
    }
  }

  @include tablet() {
    padding: 0;
    position: relative;
  }

  .button {
    width: 100%;
    max-width: 100%;
    box-shadow: 0 15px 35px rgba(19, 40, 76, 0.6);
    @include tablet() {
      box-shadow: $box-shadow;
      max-width: 420px;
    }
  }
}

.product-info {
  .ilinks:not(:last-child) {
    padding: 0;
  }
}

.ilinks {
  //height: 2.625rem;
  display: flex;
  justify-content: center;
}

.ilinks__item {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  font-weight: $weight-medium;
  text-transform: uppercase;
  width: 100%;
  text-align: center;

  &--border {
    border-right: 1px solid $grey-3;
  }
}

.link-label {
  font-size: $size-xsmall;
  font-weight: $weight-bold;
  white-space: nowrap;
  @include desktop() {
    font-size: $size-small;
  }

  .eb {
    font-size: $size-xsmall - 0.1rem;
    margin-left: 0;

    @include desktop() {
      margin-left: 0.3rem;
      font-size: $size-small - 0.1rem;
    }
  }
}

.cheapest-menu {
  text-align: center;
  margin-bottom: 0.5rem;
}

.cheapest-menu__normal {
  margin-right: 0.8rem;
  color: $grey-4;
  text-decoration: line-through;
  font-size: 0.9375rem;
  font-weight: $weight-bold;
}

.cheapest-menu__sale {
  font-size: 1.5625rem;
  font-weight: $weight-black;
  color: $red;
}

.cheapest-menu__sign {
  font-size: $size-xsmall;
}

.product-display {
  overflow: hidden;
  border-radius: $radius-large;
  box-shadow: $box-shadow;
}

.button.booking-button {
  border-radius: $radius-large;
  padding: 0.9rem 0 0.9rem 0 !important;
  text-align: center;
  @include tablet() {
    border-radius: $radius-large;
  }
}

.filter-sort-column {
  display: block;

  @include tablet() {
    display: none;
  }
}

.filter-tags-column {
  .filter-icon {
    display: none;
    @include tablet() {
      display: block;
    }
  }
}

.more-menu {
  font-weight: $weight-light;
}

.show-menu {
  font-weight: $weight-bold;
  text-transform: uppercase;
}

.show-menu__item {
  cursor: pointer;
  white-space: nowrap;
  min-width: 1px;
  font-weight: $weight-normal;

  &:not(:last-child) {
    margin-bottom: 0;
    @include tablet() {
      margin-bottom: 5px;
    }
  }

  &.more-menu {
    font-weight: $weight-light;
  }
}

.select--dropdown {
  overflow: initial !important;

  .dropdown-trigger .button {
    justify-content: flex-start;
  }

  .is-disabled {
  }

  .dropdown-item:not(.is-disabled) {
    color: $black;
    font-weight: 500;
  }

  .button {
    font-size: 1em;
    font-weight: $weight-normal;
    padding-left: 1rem;
  }

  .dropdown-trigger,
  .button,
  .dropdown {
    border: 0;
    height: 100%;
    width: 100%;
  }
}

.select--haslabel {
  position: relative;
  overflow: hidden;
  border: 2px solid #eeeeee;
  border-radius: $radius-large;

  select {
    opacity: 0;
    z-index: 2;
    @include mobile() {
      position: absolute;
      left: -60px;
      right: 0;
      top: 0;
      bottom: 0;
      width: 150% !important;
      max-width: 150% !important;
    }
  }

  &.disabled {
    .select-label {
      background-color: #f5f5f5;
      border-color: #f5f5f5;
      color: #8b8d8f;
      pointer-events: none;
      cursor: not-allowed;
    }
  }
}

.select-label {
  cursor: pointer;
  display: block;
  font-size: 1em;
  font-weight: $weight-normal;
  max-width: 100%;
  color: #000;
  z-index: 1;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding-left: 14px;
  padding-top: 0.69rem;
  height: 3.125rem;
  justify-content: flex-start;
  align-items: center;
  line-height: 1.5;
}

.product__times {
  font-weight: $weight-bold;
  //@include tablet() {
  //  font-weight: $weight-normal;
  //}
}

.product-preview {
  position: relative;
  background: $white;
  height: 430px;
}

#product-page-wrap {
  position: relative;
  margin-top: 0;
  z-index: 2;
  @include tablet() {
    margin-top: -($productContentPadding + 0.5rem);
    background: $white;
  }
}

#product-page {
  padding: 0 1.1rem;
  background: $white;
  margin-left: 0;

  @include tablet() {
    padding: $productContentPadding;
    margin-left: 10px;
  }
}

.product-list-wrap {
  box-shadow: none;
  margin-top: 1.5rem;
}

.filter-column {
  position: relative;
  z-index: 2;

  &.filter-column--active {
    z-index: 3;
  }
}

.filter-column__header {
  position: relative;
  overflow: hidden;

  .filter-head {
    display: flex;
    align-items: center;
  }

  .filter-head__title {
    padding-right: 0;
    padding-top: 0;
  }

  .filter-head__filter {
  }

  .dropdown-trigger .button {
    padding-left: 0;
  }
}
