.eb {
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    &.is-#{$name} {
      color: $color;
    }
  }

  @each $name, $shade in $shades {
    &.is-#{$name} {
      color: $shade;
    }
  }

  &.is-right {
    position: absolute;
    right: 1.875rem;
  }
}
