@use "sass:math";

@import '../all_vars';

.lazy-bg {
  // transparent gift
  background-image: url('data:image/gif;base64,R0lGODlhAQABAIABAP///wAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==') !important;
}

.eb-circle {
  margin-right: $size-small;
  margin-bottom: $size-small;
  background-color: $grey;
  color: $grey-darker;
  position: relative;
  display: inline-block;
  border-radius: $radius-rounded;
  padding: 0.55rem 0.7rem;
  width: $size-small + 1.5rem;
  height: $size-small + 1.5rem;
  font-size: $size-normal;

  &.is-red {
    background-color: $red-dark;
    color: $red-dark-invert;
  }

  &.is-white {
    background-color: $white;
    color: $red-dark;
  }

  &.font-blue {
    color: $blue;
  }

  &.is-small {
    padding: 0.35rem 0.5rem;
    width: 1.975rem;
    height: 1.975rem;
    font-size: 1rem;
  }

  &.is-medium {
    padding: $size-medium * 0.5;
    width: $size-medium + 2rem;
    height: $size-medium + 2rem;
    font-size: $size-medium;
  }

  &.is-large {
    padding: $size-large * 0.5;
    width: $size-large + 3rem;
    height: $size-large + 3rem;
    font-size: $size-large;
  }

  .eb {
    //position: absolute;
  }
}

.navbar-brand .logo {
  margin-left: 0.5rem;
}

.logo {
  @include eb-icon('../assets/images/logo-se', 131px, 14px);
}

body.test-site {
  .logo {
    @include eb-icon('../assets/images/logotest-se', 155px, 14px);
  }
}

.logo-sv {
  @include eb-icon('../assets/images/logo-se', 131px, 14px);
}

body.test-site {
  .logo-sv {
    @include eb-icon('../assets/images/logotest-se', 155px, 14px);
  }
}

.logo-da {
  @include eb-icon('../assets/images/logo', 131px, 14px);
}

body.test-site {
  .logo-da {
    @include eb-icon('../assets/images/logotest', 155px, 14px);
  }
}


.credit-cards {
  @include eb-icon('../assets/images/pay', 235px, 36px);
  margin: 0 5px;
}

.ic-gplay {
  @include eb-icon('../assets/images/iconsv2/gplay', 100%, auto);
}

.ic-aplay {
  @include eb-icon('../assets/images/iconsv2/aplay', 100%, auto);
}

.eb-trust-pilot {
  @include eb-icon('../assets/images/iconsv2/aplay', 174, 44);
}

.eb-sort {
  @include eb-icon('../assets/images/iconsv2/red-sort-arrows', 15px, 22px);
}

.list-showcase {
  display: flex;
  align-items: center;
  color: $black;
  font-size: 0.875rem;
}

.list-showcase__icon {
  min-width: 35px;
  height: 100%;
}

.list-showcase__content {
  font-size: inherit;
  height: 100%;
}

.list-showcase__item {
  font-weight: 500 !important;
  color: $times-color;
}

.ic-clock,
.list-showcase__icon--clock {
  @include eb-icon('/assets/images/iconsv2/clock', 23px, 23px);
  background-repeat: no-repeat;
}

// flags
// this flags are from lipis/flag-icon-css
// usage: <span class="eb-flag eb-flag-dk"></span>
.eb-flag {
  background-size: 101%;
  background-position: 50%;
  background-repeat: no-repeat;
  border: 2px solid $white;

  position: relative;
  display: inline-block;

  $size-eb-flag: math.div(4, 3) * 1.4rem;
  width: $size-eb-flag;
  height: $size-eb-flag;
  border-radius: $radius-rounded;
  margin-right: 0.2rem;
}

.eb-flag-da {
  @include eb-flag(dk);
}

.eb-flag-en {
  @include eb-flag(us);
}

.eb-flag-sv {
  @include eb-flag(se);
}

.tyhand {
  @include eb-bg('../assets/images/tyhand.svg');
  background-repeat: no-repeat;
  width: 100%;
  position: relative;
  background-size: 100%;
  background-position: top center;
  height: 550px;

  @include tablet() {
    height: 600px;
  }
}