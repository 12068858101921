@use "sass:math";

$box-floating-height: 7.5rem;

.box-floating {
  position: relative;
  padding-top: ($box-floating-height * 0.5) + 1rem; // 1rem top space

  .is-floating {
    max-width: 290px;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    top: 0;
    left: 2.125rem;
    right: 2.125rem;
    margin-top: -($box-floating-height * 0.5);

    @include tablet() {
      padding-left: 0.5rem;
      padding-right: 0.5rem;

      > .box {
        padding: 2rem;
      }
    }

    @include desktop() {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    > .box {
      padding-left: 1rem;
      padding-right: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      height: $box-floating-height;
    }

    img {
      max-width: 7rem;
      margin: 0 auto;
    }
  }

  > .box {
    padding-top: $box-floating-height * 0.5;
  }
}

.box {
  // max-width: 21.875rem;
  margin: 0 auto;
  position: relative;
  z-index: 1;

  @include tablet() {
    padding: $box-padding - 1rem;
  }

  @include desktop() {
    padding: $box-padding;
  }
}

.bxx {
  strong,
  * {
    color: inherit;
  }

  .subtitle {
    line-height: 1.5;
  }

  .box {
    .subtitle {
      font-weight: $weight-normal;
    }
  }
}

//width: 254px;
//height: 647px;
$featuredPhoneWidth: 203px;
$featuredPhoneHeight: 517px;
.boxlet-download {
  z-index: 10;
  overflow: visible !important;
  margin-top: 0;

  @include desktop() {
    margin-top: 60px;
  }
}

.featured-phone {
  position: relative;
  z-index: 10;
  left: 0;
  top: 0;
  width: $featuredPhoneWidth;
  height: math.div($featuredPhoneHeight, 1.7);
  overflow: hidden;
  margin: 0 auto 3rem auto;

  @include desktop() {
    position: absolute;
    left: 0;
    top: -320px;
    width: auto;
    height: auto;
  }
}

.featured-phone__image {
  width: $featuredPhoneWidth;
  height: $featuredPhoneHeight;
  background-size: $featuredPhoneWidth $featuredPhoneHeight;
  background-repeat: no-repeat;

  @include desktop() {
    width: $featuredPhoneWidth;
    height: $featuredPhoneHeight;
    background-size: $featuredPhoneWidth $featuredPhoneHeight;
    background-repeat: no-repeat;
  }

  @include retina-img('/assets/images/phone', 'png');
}
