.buttons {
  &.is-block {
    max-width: 336px;
    margin-left: auto;
    margin-right: auto;

    .button {
      width: 100%;
      display: block !important;
    }

    .has-icon {
      padding-right: 2.5rem;
      padding-left: 2rem;
    }
  }

  &.is-switcher {
    position: relative;
    color: $grey-dark;
    border-radius: $radius-large;
    letter-spacing: 2px;
    margin-bottom: 2rem;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 100%;

    @include tablet() {
      max-width: 420px;
    }

    @include widescreen() {
      max-width: 520px;
    }

    &.is-active {
      .button.is-selected {
        color: $grey-dark;

        .button-slider {
          display: none;
        }
      }

      .button {
        color: $red-dark;

        .button-slider {
          display: block;
        }
      }
    }

    .button {
      border: 0;
      width: 50%;
      text-transform: uppercase;
      background-color: $grey;
      font-weight: $weight-black;
      font-size: $size-small;
      padding: $size-large 0;
      margin: 0 !important;
      z-index: 1;
      display: block;

      &:focus {
        box-shadow: none;
      }

      &.l.button-slider {
        left: 0;
      }

      &.r.button-slider {
        right: 0;
      }

      .button-slider-text {
        position: relative;
        z-index: 10;
        display: block;
        letter-spacing: 1px;
      }

      .button-slider {
        width: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        background-color: $white;
        z-index: 1;
        box-shadow: $box-shadow;
        border-radius: $radius-large;
        display: none;
      }

      &.is-selected {
        color: $red-dark;

        .button-slider {
          display: block;
        }
      }

      &.has-icon {
        .button-slider-icon {
          font-size: $size-small + 0.2rem;
          margin-right: 0.3rem;
          bottom: -2px;
          position: relative;
        }
      }
    }
  }
}

.button {
  height: auto;
  border-radius: $radius-large;
  font-weight: $weight-semibold;

  .is-button-increase {
    border-radius: $radius-rounded;
    border: 0;
  }

  &.is-plain:focus {
    outline: none;
    box-shadow: none;
    border-color: #eeeeee;
  }

  &.is-change-parent {
    width: 100%;
    margin-right: 0;
    padding: 0;
    @include tablet() {
      margin-right: 1rem !important;
      line-height: 1.1;
      padding: 0.76rem 0 !important;
    }
  }

  &.is-filter {
    padding: 1rem 1.5rem;
    letter-spacing: 1px;
    margin-top: 0.1rem;
    margin-right: 0.7rem;
    border-color: #eeeeee;
  }

  &.is-padded {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
  }

  &.is-small {
    border-radius: $radius-large;
  }

  &.is-rounded-large {
    border-radius: $radius-large;

    &.is-small {
      border-radius: $radius-large;
    }
  }

  &.is-xlarge {
    //margin-top: 3.125rem;
    padding: 0.7rem 0;
    font-size: 1rem;
  }

  &.is-huge {
    //margin-top: 3.125rem;
    font-size: 1rem;
    margin-bottom: 1.1rem;
    padding: 0.9rem 3rem;
    @include tablet() {
      padding: 0.9rem 3rem;
    }
  }

  &.is-box {
    box-shadow: $box-shadow;
    color: $red-dark;
    border-radius: $radius-large;
    border: 0;
    width: 100%;
    height: 100%;
    padding: 0.3rem;
    word-break: break-word;
    white-space: normal;

    @include desktop() {
      padding: 1rem;
    }
  }

  .icon-left {
    margin-right: 1rem;
    font-size: 1.3rem;
  }

  .icon-right {
    margin-left: 1rem;
    font-size: 1.3rem;
  }

  .icon {
    font-size: 1.475rem;
    padding-left: $size-small;
    padding-right: $size-small;

    &.is-right {
      position: absolute;
      right: 1.3rem;
      top: 8px;
    }

    &.is-left {
      position: absolute;
      left: 1.3rem;
    }
  }
}
