$menu-transition: all 0.3s ease-in-out;

.menu-floating {
  position: absolute;
  overflow: visible;
  left: 0;
  right: 0;
  top: -100%;
  bottom: 100%;
  background: $white;
  z-index: 10;

  transition: $menu-transition;

  &.is-active {
    top: $navbar-height;
    bottom: 0;
    transition: $menu-transition;
  }

  .menu {
    position: absolute;
    overflow: auto;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }
}

.menu.is-mobile {
  font-weight: $weight-normal;

  .menu-label {
    border: 0 $grey solid;
    border-bottom-width: 1px;
    border-top-width: 1px;
    background-color: $grey-light-slight;
    font-size: $size-small;
    font-weight: $weight-black;
    color: $black;
    padding: 0.625rem 1.5rem;
    line-height: 1.8;
    margin: 0;
  }

  li > .menu-label {
    margin: 0 0 0 -1.5rem;
  }

  .menu-list {
    margin-left: 1.5rem;
    a {
      font-size: $size-normal;
      border: 0 $grey solid;
      border-bottom-width: 1px;
      border-top-width: 1px;
      padding: 0.938rem 0 0.938rem;
      position: relative;

      &.has-icon {
        padding-left: 3rem;
      }

      .icon {
        position: absolute;
        margin-top: -9px;
        width: 35px;
        height: 35px;
        display: block;
        padding: 0.57rem;

        &.is-left {
          left: 0;
        }

        &.is-right {
          top: 24px;
          right: 0;
          img {
            width: 6px;
          }
        }
      }
    }
  }
}
