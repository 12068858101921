// borders

.is-rounded {
  border-radius: $radius-rounded;
  position: relative;
  overflow: hidden;
  padding: 7px;
  margin-left: 2px;
  margin-right: 2px;
}

.has-radius-xlarge {
  border-radius: $radius-large;
}

.is-border-3 {
  border: 3px solid $grey;
}

.is-border-right-2 {
  border-right: 2px solid $grey;
}

// padding
.no-pd {
  padding: 0 !important;
}

// padding
.no-pdl {
  padding-left: 0 !important;
}

.no-pdr {
  padding-right: 0 !important;
}

.pd-xsmall {
  padding: $size-xsmall;
}

.pd-small {
  padding: $size-small;
}

.pd-normal {
  padding: $size-normal;
}

.pd-large {
  padding: $size-large;
}

.pd-xlarge {
  padding: $size-xlarge;
}

.pd-tb-xsmall {
  padding: $size-xsmall 0;
}

.pd-tb-small {
  padding: $size-small 0;
}

.pd-tb-normal {
  padding: $size-normal 0;
}

.pd-tb-large {
  padding: $size-large 0;
}

.pd-tb-xlarge {
  padding: $size-xlarge 0;
}

.no-mr {
  margin: 0 !important;
}

.mr-b-large {
  margin-bottom: $size-xlarge !important;
}

.mr-b-medium {
  margin-bottom: $size-medium !important;
}

.mr-b-small {
  margin-bottom: $size-small !important;
}

.mr-t-large {
  margin-top: $size-xlarge !important;
}

.mr-t-medium {
  margin-top: $size-medium !important;
}

.mr-t-small {
  margin-top: $size-small !important;
}

// positioning
.is-flex-mid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row wrap;
}

.is-flex-mid-left {
  @extend .is-flex-mid;
  justify-content: flex-start;
  height: 100%;
}

.is-flex-mid-col {
  display: flex;
  //align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  height: 100%;
  width: 100%;
}

.has-dark-background {
  color: $white;

  .title {
    color: $red;
  }

  .subtitle {
    color: $white;
  }
}

.is-full {
  width: 100% !important;
}

.is-m-full {
  width: auto;
  @include mobile() {
    width: 100%;
  }
}

.is-fullheight {
  height: 100% !important;
}

.is-m-floating {
  position: fixed !important;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  padding: 1rem !important;
  margin-bottom: 2rem !important;
  @include tablet() {
    position: relative !important;
    padding: 0 !important;
  }
}

.has-text-weight-black {
  font-weight: $weight-black;
}

.disabled {
  cursor: not-allowed;

  select,
  input,
  text-area {
    background-color: $input-disabled-background-color;
    border-color: $input-disabled-border-color;
    color: $input-disabled-color;
    pointer-events: none;
    cursor: not-allowed;

    + placeholder {
      color: $input-disabled-placeholder-color;
    }
  }

  &.select:not(.is-multiple):not(.is-loading)::after {
    border-color: $grey-dark;
  }
}

// shadow
.has-shadow {
  box-shadow: 0 20px 50px 0 rgba($blue-ter, 0.2);
}

.has-shadow-l {
  box-shadow: -20px 0 50px 0 rgba($blue-ter, 0.2);
}

.has-shadow-desktop {
  box-shadow: none;
  @include desktop() {
    box-shadow: 0 20px 50px 0 rgba($blue-ter, 0.2);
  }
}

.no-shadow {
  box-shadow: none !important;
}

.content {
  &.is-small-width {
    max-width: 620px;
    margin: 0 auto;
  }

  &.is-medium-width {
    max-width: 1000px;
    margin: 0 auto;
  }

  &.is-large-width {
  }
}

.spacer {
  @include clearfix;
  height: 20px;
  width: 100%;
}

.spacer-s {
  @include clearfix;
  height: 10px;
  width: 100%;
}

.spacer-xs {
  @include clearfix;
  height: 5px;
  width: 100%;
}

.spacer-block {
  @include clearfix;
  height: $block-spacing;
}

.has-letter-space {
  letter-spacing: 1px !important;
}

.is-ellapsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}

.is-columns-reverse-mobile {
  .columns {
    @include mobile() {
      display: flex;
      flex-flow: column-reverse;
    }
  }
}

.has-max-width {
  max-width: 580px;
  margin-left: auto;
  margin-right: auto;
}

.br-0 {
  border-width: 0 !important;
}

.m-reverse {
  @include mobile() {
    display: flex;
    flex-flow: column-reverse;
  }
}

.color-red {
  color: $red !important;
}

.is-sm {
  font-size: 0.9rem;
  font-weight: $weight-medium;
}

.is-cursor-pointer {
  cursor: pointer;
  //user-select: none;
}

.has-text-shadow {
  text-shadow: 2px 2px #000;
}

.no-event {
  pointer-events: none;
}

.line-height-1 {
  line-height: 1;
}

.subscribe-ck {
  padding-left: 2.3rem !important;
  padding-top: 0 !important;
}
