
=smallDevice
  @media screen and (max-width: $smallDevice)
    @content

// override menu burger
=hamburger($dimensions)
  cursor: pointer
  display: block
  height: $dimensions
  position: relative
  width: $dimensions

  // MOD: add is-left & is-right modifier
  &.is-left
    span
      &:nth-child(2)
        margin-left: 0

  &.is-right
    span
      &:nth-child(2)
        margin-left: 6px

  span
    background-color: currentColor
    display: block
    height: 2px
    left: calc(50% - 8px)
    position: absolute
    transform-origin: center
    transition-duration: $speed
    transition-property: background-color, opacity, transform
    transition-timing-function: $easing
    border-radius: 5px
    width: 21px // MOD: width
    &:nth-child(1)
      top: calc(50% - 8px) // MOD: add 2px space
    &:nth-child(2)
      top: calc(50% - 1px)
    &:nth-child(3)
      top: calc(50% + 6px) // MOD: add 2px space
  &:hover
    background-color: rgba(black, 0.05)
  // Modifers
  &.is-active
    span
      &:nth-child(1)
        transform: translateY(7px) rotate(45deg) // MOD: add 2px space
      &:nth-child(2)
        opacity: 0
      &:nth-child(3)
        transform: translateY(-7px) rotate(-45deg) // MOD: add 2px space


=arrow($color: transparent)
  border: 2px solid $color // MOD: border width
  border-radius: 2px
  border-right: 0
  border-top: 0
  content: " "
  display: block
  height: 0.5em // MOD: height
  margin-top: -0.35em // MOD: margin top 70% of width
  pointer-events: none
  position: absolute
  top: 50%
  transform: rotate(-45deg)
  transform-origin: center
  width: 0.5em // MOD: width

%arrow
  +arrow