.image {
  &.is-center {
    margin-left: auto;
    margin-right: auto;
  }
  &.is-174x44 {
    width: 174px;
    height: 44px;
  }
}
