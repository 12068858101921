@use "sass:math";

@mixin retina-img($file, $type) {
  @if $type == base64 {
    background-image: url($file);
  } @else {
    background-image: url($file + '.' + $type);
  }

  @media screen and (-webkit-min-device-pixel-ratio: 2) {
    @if $type == base64 {
      background-image: url($file);
    } @else {
      background-image: url($file + '@2x.' + $type);
    }
  }
}

@mixin eb-icon($path, $width, $height) {
  width: $width;
  height: $height;
  background-size: $width $height;

  //@include retina-img($path, "png", $width, $height);

  &:not(.lazy-bg-loaded),
  &.lazy-bg-loaded {
    @include retina-img($path, 'png');
  }
}

@mixin eb-bg($path) {
  &:not(.lazy-bg-loaded),
  &.lazy-bg-loaded {
    background-image: url($path);
  }
}

@mixin eb-flag($flag) {
  background-image: url('../assets/images/flags/#{$flag}.svg');

  &:before {
    content: '\00a0';
  }

  .is-small {
    $size-eb-flag: math.div(4, 3) * 1rem;
    width: $size-eb-flag;
    height: $size-eb-flag;
  }
}
