@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@import './bulma';

// font icons
@import './fonts/fonts.scss';
@import './fonts/extra.scss';

// modify
@import './helpers';
@import './custom/navbar';
@import './custom/menu';
@import './custom/buttons';
@import './custom/section';
@import './custom/box';
@import './custom/card';
@import './custom/container';
@import './custom/column';
@import './custom/section';
@import './custom/images';
@import './custom/icons';
@import './custom/tag';
@import './custom/input';
@import './custom/steps';
@import './custom/table';
@import './custom/tools';
@import './custom/content';
@import './custom/video';
@import './custom/footer';
@import './custom/tag-price';
@import './custom/product';

// earlybird styles
@import './earlybird/box-admin';
@import './custom/accordion';
@import './earlybird/page';
@import './earlybird/main';

html {
  // remove 300ms delay
  touch-action: manipulation;
}

html,
body {
  -webkit-tap-highlight-color: transparent;
  background: #fff;
}

.tabs {
  a.is-active {
    border-bottom-color: $tabs-link-active-border-bottom-color;
    color: $tabs-link-active-color;
  }
}

// force remove padding to match child height with container
.eb-ellipsis {
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;

  a,
  > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.eb-js-ellipsis {
  p {
    margin: 0 !important;
  }

  a {
    margin: 0 !important;
  }
}

//ie remove element border
a,
image {
  border-style: none;
  outline: none;

  &:hover,
  &:focus {
    outline: none;
  }
}

// add fade in effect on lazy loaded image
img {
  &.lazy {
    opacity: 0;
  }

  &.imgl {
    opacity: 0;

    &.imgl-in {
      transition: 0.2s opacity ease-out;
      opacity: 1;
    }
  }
}

// facebook iframe
// required by  responsivefb.js
.fb-page-iframe {
  max-width: 400px;
  width: 100%;
}

.invi-select {
  position: absolute !important;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  opacity: 0;
  display: block;
  appearance: none;
  @include mobile() {
    left: -60px;
    width: 150%;
  }

  @include tablet() {
    display: none;
  }

  &.invi-select--ios-fix {
    @include mobile() {
      min-width: 760px * 2 !important;
      left: -760px;
    }
  }
}

.time-separator {
  color: $grey-dark;
}

.hide {
  display: none !important;
}

.pop-product-filters {
  display: none;
  @include tablet() {
    display: block;
  }
}

#pop-product-filters {
  .pop-page-content {
    padding-bottom: 0 !important;
  }

  .pop-page-content {
    transition: padding-top 50ms ease-in-out;
  }
}

.section.section-box.banner {
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
}

.menu-main .navbar.is-primary .navbar-brand > a.navbar-item:hover {
  background-color: $primary;
}

.btn-newsletter-submit {
  min-width: 35px !important;
}

.select select {
  //@include smallDevice() {
  //  padding-right: 30px !important;
  //}
}

.select-hide {
  display: none;
}

.post-breadcumbs.featured {
  padding: 1rem;
  margin: 0 auto;
  @include desktop() {
    padding-left: 0;
    padding-right: 0;
  }
}

//.button {
//  justify-content: center;
//  height: auto;
//}

//select, .button, .input, .textarea, .select select, .file-cta, .file-name, .pagination-previous, .pagination-next, .pagination-link, .pagination-ellipsis {
//  border: $control-border-width solid $input-border-color;
//}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.info-view .content a {
  text-decoration: none !important;
}
