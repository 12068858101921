.pprice {
  text-transform: uppercase;
  white-space: nowrap;
  text-align: right;
  font-size: 1rem;
  letter-spacing: 0.5px;
  font-weight: $weight-bold;
}

.pprice__tag:not(body) {
  text-decoration: none;
  position: relative;
  display: inline-block;
  font-size: $size-5;

  @include tablet() {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  @include desktop() {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  small {
    font-size: $size-8;
  }

  &:last-child {
    margin-right: -0.75rem;
  }
}

.pprice__tag--sale:not(body) {
  background-color: $red-light;
  color: $red-dark;
  font-weight: $weight-black;
}

.pprice__tag--normal {
  // add not(body) to modify tag
  &:not(body) {
    color: $grey-dark;
    text-decoration: line-through;
    background-color: $white;
  }
}

.pprice__tag--multiline:not(body) {
  width: auto;
  display: inline-block;
  text-align: center;
  line-height: 1.1;
  min-height: $control-height;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  small {
    // move currency symbol to next line
    display: block;
  }
}
