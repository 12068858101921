// $navbar-background-color: $primary;
// $navbar-item-active-color: #3d414d;

.navbar-item--pill {
  .icon {
    display: block;
    width: auto;
    img {
      min-height: 100%;
      max-height: 35px;
    }
    &.is-float-left {
      margin: -25px -5px -12px -11px;
    }
  }

  &.navbar-item {
    align-items: center;
    margin: 0 5px;
  }

  > .navbar-link {
    font-size: $size-8;
    padding-right: 3rem;
    min-width: 180px;
    border-radius: $radius-large;
    background-color: $blue-light;
  }

  &.navbar-item.has-dropdown:hover .navbar-link {
    background-color: lighten($blue-light, 4%) !important;
  }
}
