section {
  overflow: hidden;
}

.figure {
  margin-top: 2em;
  margin-bottom: 2em;
}

.section {
  z-index: 1;

  &.section-loading {
    padding-top: $block-spacing + 2rem !important;
    padding-bottom: $block-spacing + 2rem !important;
  }

  &.section-box {
    padding-top: $block-spacing + 5rem;
    padding-bottom: $block-spacing + 5rem;
    position: relative;
    //overflow: hidden;
  }

  &.section-box--small {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  &.has-cover {
    overflow: hidden;
    position: relative;
  }

  &.has-colored {
    color: $white;

    .title {
      //color: $red;
    }

    .subtitle {
      color: $white;
    }

    &.is-primary {
      background: $primary;
    }

    .card .title {
      color: $text;
    }
  }

  .subtitle {
    font-weight: $weight-semibold;
    @include mobile() {
      font-size: 0.95rem;
    }
  }

  .title.is-3 {
    @include mobile() {
      font-size: 1.375rem;
    }
  }

  .box-floating {
    display: block;
    width: 100%;
    @include tablet() {
      flex-direction: column;
      display: flex;
      height: 100%;
    }

    > .box {
      display: block;
      flex-direction: unset;
    }
  }

  .box {
    display: block;
    width: 100%;
    @include tablet() {
      flex-direction: column;
      display: flex;
      height: 100%;
    }

    .title {
      font-size: $size-large;
      margin-bottom: 2.5rem;
    }

    .subtitle {
      font-size: $size-normal;
    }
  }

  .button.is-wide {
    margin-top: $block-spacing;
    //margin-bottom: $block-spacing;
    min-width: 100%;
    width: 100%;
    letter-spacing: 1px;
    text-transform: uppercase;

    @include tablet() {
      min-width: 420px;
      width: auto;
    }
  }

  .section-content {
    z-index: 2;
    position: relative;

    .button {
      &.is-wide,
      &.is-huge {
        @include mobile() {
          font-size: $size-small;
          padding-left: 0.5rem;
        }

        .icon {
          @include mobile() {
            font-size: 1.2rem;
            top: 10px;
          }
        }
      }
    }
  }

  .container,
  .container-small {
    & > .content {
      margin-bottom: 3.125rem;
    }
  }

  // adjust radius and padding

  .section-content .card {
    &.card-list {
      border-radius: $radius-large;

      .card-image {
        padding: 1rem;

        .image {
          border-radius: $radius-large;
          box-shadow: $box-shadow;
        }
      }

      .card-content {
        text-align: left;
        padding: 0 1.5rem 1.5rem;
        @include tablet() {
          padding: 0 1.5rem;
        }

        .title {
          font-size: $size-medium;
          margin-bottom: 0;
        }

        .summary {
          font-size: $size-normal;
        }
      }
    }
  }
}

.boxlet-bg-color {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}

.section-cover-wrap {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
}

.section-cover {
  //position: absolute;
  height: 100%;
  width: 100%;
  //top: -50%;
  //left: -50%;
  //width: 200%;
  //height: 200%;
  z-index: 2;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    //position: absolute;
    //top: 0;
    //left: 0;
    //right: 0;
    //bottom: 0;
    //margin: auto;
    //min-width: 50%;
    //min-height: 50%;
    //// reset width & max-width to enable the stretch in small screen
    //max-width: inherit;
  }
}

.bxx--large-bg {
  .section-cover img {
    @include mobile() {
      padding-top: 35%;
    }
  }
}
